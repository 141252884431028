<template>
  <v-container id="general-statistics" class="mt-6">
    <!-- Row 1 -->
    <v-row>
      <!-- General statistics -->
      <GeneralStatistics class="fade-in one" v-bind="{ generalStats }" />

      <!-- Hectar statistics -->
      <HectarStatistics class="fade-in two" v-bind="{ hectarStats }" />
    </v-row>

    <!-- Row 2 -->
    <v-row class="mb-8 mt-0">
      <!-- Financial Statistice -->
      <FinancialStatistice
        v-if="$hasPermission('statistics', 'statistics', 'list')"
        class="fade-in three"
        v-bind="{ financialStats }"
      />

      <!-- Maintenance Statistics -->
      <MaintenanceStatistice
        class="fade-in four"
        v-bind="{ maintenanceStats }"
      />
    </v-row>
  </v-container>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import axios from 'axios'
import GeneralStatistics from '@/components/statistics/GeneralStatistics'
import HectarStatistics from '@/components/statistics/HectarStatistics'
import FinancialStatistice from '@/components/statistics/FinancialStatistice'
import MaintenanceStatistice from '@/components/statistics/MaintenanceStatistice'
import {
  statisticsService,
  statsService,
  userService,
  bondsService
} from '@/services'
export default {
  name: 'HomeDetails',
  data() {
    return {
      generalStats: {},
      hectarStats: {},
      maintenanceStats: {},
      financialStats: {}
    }
  },
  components: {
    GeneralStatistics,
    HectarStatistics,
    FinancialStatistice,
    MaintenanceStatistice
  },
  computed: {
    ...mapState('accountState', ['currentEnv'])
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    addPathsToFinancialStats() {
      this.financialStats.map((state) => {
        switch (state.nameEn) {
          case 'customerDues':
            state.path = 'claims'
            break
          case 'arrears':
            state.path = 'backLog'
            break
          default:
            state.path = ''
        }
      })
    },
    async getGeneralStats() {
      try {
        const environment = this.currentEnv._id

        const [realEstate, contracts, clients, bonds] = await axios.all([
          statisticsService.realEstateStats({ environment }),
          statisticsService.contractsStats({ environment }),
          userService.getClientsCountByStatus(environment),
          bondsService.getBondCountByType(`environment=${environment}`)
        ])

        this.generalStats = {
          realEstate: realEstate.data,
          contracts: contracts.data.stats,
          totalOwners:
            clients.data.result.find((res) => res.role === 'owner')?.count || 0,
          totalRenters:
            clients.data.result.find((res) => res.role === 'renter')?.count ||
            0,
          totalClients: clients.data.totalCount,
          totalBonds: bonds.data.totalBonds.count
        }
      } catch {
        this.addNotification({
          text: 'حدث خطا في تحميل الاحصائيات العامة',
          color: 'error'
        })
      }
    },
    async getHectarStats() {
      try {
        const { data } = await statisticsService.hectarStats({
          environment: this.currentEnv._id
        })
        this.hectarStats = data
      } catch {
        this.addNotification({
          text: 'حدث خطا في تحميل احصائيات هكتار',
          color: 'error'
        })
      }
    },
    async getFinancialStats() {
      try {
        const { data } = await statsService.getClientStats({
          environment: this.currentEnv?._id,
          dashboard: true
        })
        this.financialStats = data.stats
        this.addPathsToFinancialStats()
      } catch {
        this.addNotification({
          text: 'حدث خطا في تحميل الاحصائيات المالية',
          color: 'error'
        })
      }
    },
    async getMaintenanceStats() {
      try {
        const { data } = await statisticsService.maintenanceStats(
          this.currentEnv._id
        )
        const { stats, totalRequests, totalExpenses } = data
        this.maintenanceStats = {
          totalRequests,
          totalExpenses
        }
        stats.forEach((stat) => {
          this.maintenanceStats[stat.status] = stat.total
        })
      } catch {
        this.addNotification({
          text: 'حدث خطا في تحميل احصائيات طلبات الصيانة',
          color: 'error'
        })
      }
    },
    progress() {
      const progress = document.querySelectorAll('.progress')
      const progress_text = document.querySelectorAll('.data-progress')
      progress.forEach((pro) => {
        const percentage = pro.getAttribute('data-value')
        const color = pro.getAttribute('data-stroke')
        const animateTime = pro.getAttribute('data-time')
        const radius = pro.r.baseVal.value
        const circumference = radius * 2 * Math.PI
        const stroke = circumference - (circumference * percentage) / 100

        pro.style.setProperty('--stroke-dashoffset', stroke)
        pro.style.setProperty('--stroke-dasharray', circumference)
        pro.style.setProperty('--stroke', color)
        pro.style.setProperty('--animation-time', `${animateTime * 50}ms`)
      })
      progress_text.forEach((text) => {
        const data = text.getAttribute('data-value')
        let progress_value = 0
        const progress_bar = setInterval(() => {
          progress_value++
          text.innerText = `${progress_value}%`
          if (progress_value == 100) {
            clearInterval(progress_bar)
          }
        }, 100)
      })
    }
  },
  created() {
    const requstes = [
      this.getGeneralStats,
      this.getHectarStats,
      this.getMaintenanceStats,
      this.getFinancialStats
    ]
    let count = 0
    const interval = setInterval(() => {
      requstes[count]()
      count++

      if (requstes.length === count) {
        clearInterval(interval)
      }
    }, 50)
  },
  mounted() {
    this.progress()
  }
}
</script>
<style lang="scss">
@import '@/styles/material-dashboard/_statistics.scss';
</style>
