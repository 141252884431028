<template>
  <div>
    <component :is="activeComponent">
      <slot />
    </component>
  </div>
</template>

<script>
import HomeDetails from '@/views/HomeDetails.vue'
import customerHome from '@/views/customerHome.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    HomeDetails,
    customerHome
  },
  computed: {
    ...mapGetters('accountState', ['isCustomer']),
    activeComponent() {
      // To be refactored to use one approach (single state)
      return this.$route.query.isCustomer === 'true' || this.isCustomer
        ? 'customerHome'
        : 'HomeDetails'
    }
  }
}
</script>
