<template>
  <main class="page-main-bg half-circle-bg pb-1" id="clientDetailsPage">
    <!-- Wrapper -->
    <div class="d-flex tab__flex__wrap mobile__flex__wrap">
      <v-container class="main-container position-relative">
        <!------------------------------------------->
        <!-------------Toolbar----------------------->
        <!-------------------------------------------->
        <tool-bar :title="userName" :customerHome="true">
          <slot />
        </tool-bar>

        <!--customer info card-->
        <CustomerCard />

        <TitleForCards @queryBuilder="getDate" />
        <v-row v-if="isStatesLoading" class="mx-1 mt-4">
          <loader v-bind="loaderProps" />
        </v-row>
        <v-row
          v-else-if="customerDefaultRole === 'renter' && !isStatesLoading"
          class="customer-stats-container"
        >
          <v-col
            cols="md"
            sm="6"
            xs="12"
            class="pt-0"
            v-for="(stat, index) in stats"
            :key="index"
          >
            <ClientDetailsTile
              class="px-0 py-0 customer-stats-card"
              :final="true"
              :stat="stat"
            />
          </v-col>
        </v-row>
        <div
          v-else-if="customerDefaultRole === 'owner'"
          class="mt-5 d-flex align-center justify-center flex-column rounded-15"
        >
          <v-row v-if="!isStatesLoading">
            <v-col
              class="ma-0 pa-2"
              cols="12"
              lg="4"
              md="4"
              sm="12"
              v-for="chartSummary in chartSummaries"
              :key="chartSummary.title"
            >
              <OwnerCards :progressData="chartSummary" />
            </v-col>
          </v-row>
          <v-row v-if="isStatesLoading" class="mx-1 mt-10">
            <loader v-bind="loaderProps" />
          </v-row>
        </div>
        <h4 class="my-auto toolbar-title font-weight-medium mt-6 mb-2">
          <span class="primary--text">إليك </span>
          <span>أحدث الرسائل والطلبات وإشعارات المطالبات الخاصة لك </span>
        </h4>

        <v-row class="files-list-container">
          <v-col cols="12" sm="6" md="4" class="pt-0">
            <FileListCard
              v-bind="messagesProps"
              @nextPageTrigger="callNextPage('messages')"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" class="pt-0">
            <FileListCard
              v-bind="requestsProps"
              @openAddRequestModal="setAddRequestDialogState(true)"
              @nextPageTrigger="callNextPage('requests')"
            />
          </v-col>
          <v-col cols="12" sm="12" md="4" class="pt-0">
            <FileListCard
              v-bind="smsMessagesProps"
              @nextPageTrigger="callNextPage('sms')"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>

    <AddRequestModal
      v-if="addRequestDialogState"
      :dialogState="addRequestDialogState"
      :addLoadingStatus="addLoadingStatus"
      :allProperties="allProperties"
      :realEstateLoading="realEstateLoading"
      :requestTypes="requestTypes"
      @toggleDialogState="setAddRequestDialogState"
      @addNewRequest="addRequest"
      @getPropertyNextData="getPropertyNextData"
      @getPropertyDataBySearchKey="getPropertyDataBySearchKey"
      @setAddLoadingStatus="setAddLoadingStatus"
    />
  </main>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import ClientDetailsTile from '@/components/details/ClientDetailsTile'
import FileListCard from '@/components/details/FileListCard'
import Loader from '@/components/helper/loader.vue'
import {
  notificationService,
  messageService,
  requestsService,
  statsService,
  realEstateService,
  reportService
} from '@/services'
import ToolBar from '@/components/listing/ToolBar'
import CustomerCard from '@/components/home/CustomerCard'
import { buildQueryString } from '@/utils/general'
import OwnerCards from '../components/home/OwnerCards.vue'
import TitleForCards from '../components/home/TitleForCards.vue'

export default {
  components: {
    ClientDetailsTile,
    FileListCard,
    TitleForCards,
    Loader,
    ToolBar,
    OwnerCards,
    CustomerCard,
    AddRequestModal: () => import('@/components/modals/AddRequestModal.vue')
  },
  data() {
    return {
      isStatesLoading: true,
      closeIcon: require('@/assets/img/svgs/state_cards_icons/arrears.svg'),
      currentBalance: require('@/assets/img/svgs/wallet-outline.svg'),
      checkmarkIcon: require('@/assets/img/svgs/checkmark-outline.svg'),
      invoiceIcon: require('@/assets/img/svgs/invoice-outline.svg'),
      houseIcon: require('@/assets/img/svgs/house-outline.svg'),
      arrowUp: require('@/assets/img/svgs/arrow-up.svg'),
      users: require('@/assets/img/svgs/user-s.svg'),
      propertiesCount: require('@/assets/img/svgs/house-b.svg'),
      house2: require('@/assets/img/svgs/house-outline.svg'),
      contractsCount: require('@/assets/img/svgs/info.svg'),
      fileListCardsData: {
        allSmsMessages: [],
        allMessages: [],
        allRequests: []
      },
      stats: [],
      chartSummaries: [],
      fileListLoading: true,
      paginationSms: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      paginationMessages: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      startDate: '',
      endDate: '',
      paginationRequests: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      // add request modal data
      addRequestDialogState: false,
      addLoadingStatus: false,
      realEstateLoading: false,
      paginationProperty: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      allProperties: [],
      properties: [],
      propertySearchKey: '',
      requestTypes: [],
      clientRoles: {
        renter: 'tenet',
        owner: 'owner'
      }
    }
  },
  watch: {
    customerDefaultRole() {
      this.clientChange()
      // get properties based on customer role
      this.paginationProperty.pageNumber = 1
      this.allProperties = []
      this.loadProperties()
    }
  },
  computed: {
    ...mapState('accountState', ['user', 'currentEnv', 'customerDefaultRole']),
    ...mapGetters('accountState', ['isCustomer']),
    statsQuery() {
      return {
        user: this.user?._id,
        environment: this.currentEnv?._id,
        userType: this.clientRoles[this.customerDefaultRole],
        apiKey: this.customerDefaultRole
      }
    },
    userName() {
      return this.user?.name
    },
    userRoles() {
      return this.currentEnv?.role
    },
    loaderProps() {
      return {
        type: 'card',
        numberOfLines: 8,
        laoderClasses: 'mx-auto'
      }
    },
    notificationQuery() {
      return {
        context: {
          platform: 'hectarPlus'
        },
        user: {
          _id: this.user._id
        },
        pageNumber: this.paginationNotifications.pageNumber,
        pageSize: this.paginationNotifications.pageSize
      }
    },
    messagesQuery() {
      return {
        user: this.user._id,
        context: 'hectarPlusCustomer',
        paginationQuery: `pageNumber=${this.paginationMessages.pageNumber}&pageSize=${this.paginationMessages.pageSize}`
      }
    },
    smsMessagesProps() {
      return {
        // http://localhost:8080/dashboard/smsMessages?phone=966555555555&name=%D8%B9%D8%A7%D8%AF%D9%84%20%D8%A7%D9%84%D9%87%D8%A7%D8%B4%D9%85&clientId=62d97ae24424d900217ff24c
        files: this.fileListCardsData.allSmsMessages,
        link: {
          name: 'عرض الكل',
          url: '/dashboard/smsMessages'
        },
        isAddAction: false,
        componentName: 'FileList',
        noData: 'لا توجد إشعارات جديده في الوقت الحالي',
        title: `أحدث الإشعارات  المطالبات (${this.paginationSms.count})`,
        fileListLoading: this.fileListLoading,
        client: this.user,
        customerHome: true
      }
    },
    requestsProps() {
      return {
        files: this.fileListCardsData.allRequests,
        link: {
          name: 'عرض الكل',
          url: '/dashboard/requests'
        },
        isAddAction: true,
        componentName: 'RequestCardContainer',
        noData: 'لا توجد طلبات جديده في الوقت الحالي',
        title: 'أحدث الطلبات',
        fileListLoading: this.fileListLoading,
        customerHome: true,
        modal: 'openAddRequestModal'
      }
    },
    messagesProps() {
      return {
        files: this.fileListCardsData.allMessages,
        link: {
          name: 'عرض الكل',
          url: '/dashboard/messaging'
        },
        isAddAction: false,
        componentName: 'MessageCardContainer',
        noData: 'لا توجد رسائل جديده في الوقت الحالي',
        title: 'أحدث الرسائل الداخلية',
        icon: {
          src: require('@/assets/img/svgs/messages/messages-icon.svg'),
          width: 20,
          height: 20
        },
        fileListLoading: this.fileListLoading,
        customerHome: true
      }
    },
    // add request modal
    isRequestTypesLoaded() {
      return !!JSON.parse(localStorage.getItem('requestTypes'))
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    ...mapMutations('accountState', ['setCustomerRole']),
    recalculatePagination(type, res) {
      this[type].count = res.totalCount || res.threads || res.count || 0
      this[type].pagesCount = Math.ceil(this[type].count / this[type].pageSize)
    },
    async getSelectedAction() {
      await this.getOwnerRenterStats()
      this.isStatesLoading = false
    },
    async clientChange(role = this.customerDefaultRole) {
      this.isStatesLoading = true
      this.getSelectedAction(role)
    },
    async getCustomerDetails() {
      try {
        await this.getAllMessages()
        await this.getAllRequests()
        await this.getAllSmsMessages()
      } catch (err) {
        this.addNotification({
          text: err,
          color: 'error'
        })
      } finally {
        this.fileListLoading = false
      }
    },
    async getAllRequests(addedRequest = false) {
      try {
        const query = buildQueryString({
          pageSize: this.paginationRequests.pageSize,
          pageNumber: this.paginationRequests.pageNumber,
          environmentId: this.currentEnv._id,
          ...(this.isCustomer && { applicant: this.user?._id })
        })
        const { data } = await requestsService.getAllRequests(query)
        this.fileListCardsData.allRequests = addedRequest
          ? data.items
          : [...this.fileListCardsData.allRequests, ...data.items]
        this.recalculatePagination('paginationRequests', data)
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل اخر الطلبات, حاول في وقت لاحق',
          color: 'error'
        })
      }
    },
    getDate({ endDate, startDate }) {
      this.startDate = startDate
      this.endDate = endDate
      this.getOwnerChartSummery()
    },
    async getAllNotifications() {
      try {
        const notificationResponse = await notificationService.getNotifications(
          this.notificationQuery
        )
        this.fileListCardsData.allNotifications = [
          ...this.fileListCardsData.allNotifications,
          ...notificationResponse.data.items
        ]
        this.recalculatePagination(
          'paginationNotifications',
          notificationResponse.data
        )
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل اخر الإشعارات, حاول في وقت لاحق',
          color: 'error'
        })
      }
    },
    async getAllMessages() {
      try {
        const messagesResponse = await messageService.getAllThreads(
          this.messagesQuery.user,
          this.messagesQuery.context
        )
        this.fileListCardsData.allMessages = [
          ...this.fileListCardsData.allMessages,
          ...messagesResponse.data.threads
        ]
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل اخر الرسائل, حاول في وقت لاحق',
          color: 'error'
        })
      }
    },
    // Pagination
    callNextPage(title) {
      // Sms
      if (
        title === 'sms' &&
        this.paginationSms.pageNumber < this.paginationSms.pagesCount
      ) {
        this.paginationSms.pageNumber += 1
        this.getAllSmsMessages()
      }
      // Requests
      if (
        title === 'requests' &&
        this.paginationRequests.pageNumber < this.paginationRequests.pagesCount
      ) {
        this.paginationRequests.pageNumber += 1
        this.getAllRequests()
      }
      // Messages
      if (
        title === 'messages' &&
        this.paginationMessages.pageNumber < this.paginationMessages.pagesCount
      ) {
        this.paginationMessages.pageNumber += 1
        this.getAllMessages()
      }
    },
    checkIfThereIsEnv() {
      if (!this.user || this.user?.environments?.length === 0) {
        this.$router.push('/setup-env')
      }
    },
    async getOwnerRenterStats() {
      const statsResponse = await statsService.getOwnerRenterStats(
        this.statsQuery
      )
      this.stats = statsResponse.data.stats || []
    },
    getStatIcon(statName) {
      const statIconsMap = {
        OwnerDues: 'currentBalance',
        currentBalance: 'currentBalance',
        receipts: 'currentBalance',
        outcomes: 'checkmarkIcon',
        arrears: 'closeIcon',
        companyRatio: 'arrowUp',
        numOfTenants: 'users',
        noOfProperties: 'propertiesCount',
        propertiesCount: 'propertiesCount',
        numOfVacancies: 'house2',
        noOfContracts: 'contractsCount',
        rent: 'house2',
        sale: 'house2',
        rented: 'house2',
        sold: 'house2',
        ExpiredRentContract: 'contractsCount',
        validRentContract: 'contractsCount',
        ExpiredPmContract: 'contractsCount',
        validPmContract: 'contractsCount',
        totalFixedCommissions: 'currentBalance'
      }
      return this[statIconsMap[statName] || 'contractsCount']
    },
    async getOwnerChartSummery() {
      // get report summery
      this.isStatesLoading = true
      const query = buildQueryString({
        environment: this.currentEnv._id,
        startDate: this.startDate,
        endDate: this.endDate,
        user: [this.user._id]
      })
      try {
        const {
          data: { stats }
        } = await reportService.getReportChart(query)
        this.chartSummaries = stats
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل البيانات, حاول في وقت لاحق',
          color: 'error'
        })
      } finally {
        this.isStatesLoading = false
      }
    },
    async getAllSmsMessages() {
      // In Dev we send messages only to Eng.Muath phone number
      const query = `user=${this.user._id}&environment=${this.currentEnv._id}&pageSize=${this.paginationSms.pageSize}&pageNumber=${this.paginationSms.pageNumber}`
      try {
        const { data } = await messageService.getClientSmsTexts(query)
        this.fileListCardsData.allSmsMessages = [
          ...this.fileListCardsData.allSmsMessages,
          ...(data.notificationLogsResult || [])
        ]
        this.recalculatePagination('paginationSms', data)
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل اخر الإشعارات, حاول في وقت لاحق',
          color: 'error'
        })
      }
    },
    // add request modal
    setAddRequestDialogState(val) {
      this.addRequestDialogState = val
    },
    setAddLoadingStatus(val) {
      this.addLoadingStatus = val
    },
    async addRequest(data) {
      this.addLoadingStatus = true
      try {
        await requestsService.addRequest(data)
        this.addNotification({
          text: 'تم إضافة الطلب بنجاح',
          color: 'success'
        })
        this.paginationRequests.pageNumber = 1
        this.getAllRequests(true)
      } catch {
        this.addNotification({
          text: 'خطأ في إضافة الطلب، برجاء المحاوله مجددا',
          color: 'error'
        })
      } finally {
        this.addRequestDialogState = false
        this.addLoadingStatus = false
      }
    },
    getPropertyNextData() {
      if (
        this.paginationProperty.pageNumber < this.paginationProperty.pagesCount
      ) {
        this.paginationProperty.pageNumber += 1
        this.loadProperties()
      }
    },
    getPropertyDataBySearchKey({ e }) {
      this.propertySearchKey = e
      if (
        this.propertySearchKey !== null &&
        this.propertySearchKey !== undefined
      ) {
        this.paginationProperty.pageNumber = 1
        this.loadProperties()
      }
    },
    async setRequestsConfig() {
      if (!this.isRequestTypesLoaded) {
        try {
          const { data } = await requestsService.getRequestsConfig()
          this.requestTypes = data.configs
          localStorage.setItem(
            'requestTypes',
            JSON.stringify(this.requestTypes)
          )
        } catch {
          this.addNotification({
            text: 'لم نتمكن من تحميل أنواع الطلبات حاول في وقت لاحق',
            color: 'error'
          })
        }
      } else {
        this.requestTypes = JSON.parse(localStorage.getItem('requestTypes'))
      }
    },
    loadProperties() {
      this.realEstateLoading = true
      let data = `pageSize=${this.paginationProperty.pageSize}&pageNumber=${this.paginationProperty.pageNumber}`
      if (this.propertySearchKey) {
        data += `&search=${this.propertySearchKey}`
      }
      const body = {
        account: this.currentEnv?.owner.id,
        user: this.user._id,
        userType: this.clientRoles[this.customerDefaultRole]
      }
      realEstateService
        .getRealEstates(body, data)
        .then((res) => {
          this.paginationProperty.pagesCount = Math.ceil(
            res?.data?.count / this.paginationProperty.pageSize
          )
          this.paginationProperty.count = res?.data?.count ?? 0
          this.properties = res?.data?.realEstates ?? []
          this.allProperties = [...this.allProperties, ...this.properties]
        })
        .catch(() => {
          this.addNotification({
            text: 'خطأ في تحميل العقارات',
            color: 'error'
          })
        })
        .finally(() => {
          this.realEstateLoading = false
        })
    }
  },
  created() {
    this.checkIfThereIsEnv()
    this.getCustomerDetails()
    this.getSelectedAction()
  },
  mounted() {
    this.loadProperties()
    this.setRequestsConfig()
    this.getOwnerChartSummery()
  }
}
</script>

<style lang="scss" scoped>
.customer-stats-container {
  margin-top: 19px;
  .customer-stats-card {
    ::v-deep(.tile-card) {
      padding-top: 18px !important;
      padding-bottom: 19px !important;
    }
  }
}
.files-list-container {
  margin-top: 15px !important;
}
</style>
